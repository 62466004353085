import React from 'react';
import { Text, StyleSheet, Image , FlatList, View, Pressable } from 'react-native';
import SomeComponent from '../CustomBackground';
import { col } from './clr';


const MapScreen = () => {
    return (
        <View style={styles.container}>
            <SomeComponent>
                <View style={{flex:13, alignSelf:"center",alignContent:"center", marginTop:'15%'}}>
                    <Text style={{fontFamily:"Bold", fontSize:50, color: "white"}}>Comming soon...</Text>
                </View>
            </SomeComponent>
        </View>
      );
};
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: col.clrMain,
    },imageContainer: {
        height:"100%",
        width:"100%"
      },
      title:{
          fontFamily: "Bold",
          color:col.clrText,
          fontSize:22,
          padding:8,
          borderRadius:30,
          borderWidth:1
      }
  });

export default MapScreen;