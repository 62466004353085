export const col = {
    // mainColor: "#ff4d01",
    // secondaryColor: "#ffc100",
    mainColor:"#fdfaee",
    secondaryColor:'#FDB44E',
    clrSecondaryDark: "#ff4800",
    clrText: "#3F4440",
    clrLight: "#E9DAC4",
    textColorPrimary: '#484848',
    textColorSecondary: '#ffffff',
    subTextColor:'#D3D3D3',
}
