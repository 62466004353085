import React from 'react';
import { Text, StyleSheet, Image , FlatList, View, Pressable } from 'react-native';
import MainBackground from '../CustomBackground'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {col} from './clr'
import { TextInput } from 'react-native-gesture-handler';


class FoodFeedScreen extends React.Component {
    render(){
        const onAddPress = () =>{
        }
        const pressableItem = (item) => {
        return(
            <Pressable style={styles.mainCardView}>
              <View style={{flexDirection: 'row', alignItems: 'center', width:"100%"}}>

                <View style={{marginLeft: 12, justifyContent:'center', flex:3}}>
                  <View style={{flexDirection:'row',alignItems:'center',flex:2}}>
                    <Text
                      style={{
                        fontSize: 16,
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'capitalize',

                      }}>
                        {item.description} 
                    </Text>
                    <Text style={{
                      color: col.subTextColor,
                      fontSize: 14,
                    }}>  
                      [ {item.quantity} ]
                    </Text>
                  </View>
                  <View style={{flexDirection:'row',alignItems:'center',marginTop:2, flex:4}}>
                    <Text
                      style={{
                        fontSize: 13,
                        color: col.secondaryColor,
                        
                      }}>
                      {item.username} 
                    </Text>
                    <Text style={{
                      color: col.subTextColor,
                      fontSize: 13,    
                    }}>  
                      [ {item.location} ]
                    </Text>
                  </View>
                </View>
                <View style={{flex:2, flexDirection:'row', }}>
                  <View style={{flex:1, justifyContent:'center',alignItems:'flex-end', }}>
                  
                  </View>
                  <Pressable
                    style={{
                      flex:1,
                      marginLeft: -30,
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      
                  }}>
                    <Text style={{color: col.mainColor,fontSize:16,fontWeight:'bold',textAlign:'center'}}>
                      {item.price}€ / piece
                    </Text>
                  </Pressable>
                </View>
              </View>
            </Pressable>
        );
    }
    return (
        <MainBackground>
          <View style={{flexDirection:"row", }}>
            <View style={{flex:4}}></View>
            <View style={{flex:2}}>
              <TextInput
                placeholder="Search"
                style={{height: 40,
                  margin: 12,
                  borderWidth: 1,
                  padding: 10,
                  borderRadius: 15}}
              />  
            </View>
            <View style={{flex:4}}></View>
          </View>



          <View style={{flexDirection:"row",}}>
            <View style={{flex:1,}}></View>
            <View style={{flex:4,  flexDirection:"column",}}>
              <FlatList
                style={{alignSelf:"center", width:"100%", overflow: "visible"}}
                contentContainerStyle={{alignSelf: 'center',alignItems:"center",  width:"100%",}}
                numColumns={Math.ceil((this.props.dataReducer2.posts.length / 2)-1)}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                data={this.props.dataReducer2.posts}
                  renderItem={({item}) =>pressableItem(item)}
                />
            </View>
          <View style={{flex:1,}}></View>
          </View>
        </MainBackground>
      );
};
}
  
const styles = StyleSheet.create({
    mainContainer:{
        flex:8,
      },
      listContainer: {
        flex: 8,
        justifyContent:'center',
        //backgroundColor:col.mainColor
       },
      mainCardView: {
        height: 150,
        width: 400,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: col.textColorPrimary,
        borderRadius: 15,
        shadowColor: 'gray',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 1,
        shadowRadius: 8,
        elevation: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 16,
        paddingRight: 14,
        marginTop: 10,
        marginBottom: 6,
        marginLeft: 10,
        marginRight: 10,
      },
  });

const mapStateToProps = (state) => {
  const { dataReducer2 } = state
  return { dataReducer2 }
};

export default connect(mapStateToProps)(FoodFeedScreen);