import React, { Component } from 'react';
import { Text, StyleSheet, Image , FlatList, View, Pressable, TextInput } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import MainBackground from '../CustomBackground'
import {col} from '../clr'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addPost } from '../reduxComponents/addPost';




class FoodFeedScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          username:'none',
          location:'none',
          description:'none',
          quantity:'none',
          price:'none',
          selectedOptions:'none'
        };
      }
    render(){
        const addPost = () =>{
            const post = {username:this.state.username,location:this.state.location,description:this.state.location,quantity:this.state.quantity,price:this.state.price};
            this.props.addPost(post);
        }
    
    return (
        <MainBackground style={styles.container}>
            <View style={{flex:1,justifyContent:'flex-end',alignItems:'center'}}>
                <Text style={{fontSize:24,color:col.mainColor}}>ADD FOOD POST</Text>
            </View>
            <View style={{flex:8,alignItems:'center',justifyContent:'space-evenly'}}>
            <View style={styles.textInputContainer}>
            <TextInput
                style={[styles.textInputStyle,{color:col.mainColor}]}
                placeholder="Product name"
                placeholderTextColor={col.mainColor}
                onChangeText={(name) => this.setState({description:name}) }
                />
                

            </View>
            
            <View style={styles.textInputContainer}>
            <TextInput
                style={[styles.textInputStyle,{color:col.mainColor}]}
                placeholder = "Quantity"
                placeholderTextColor={col.mainColor}
                onChangeText={(quantity) => this.setState({quantity:quantity}) }
                />
                

            </View>
            <View style={styles.textInputContainer}>
            <TextInput
                style={[styles.textInputStyle,{color:col.mainColor}]}
                placeholder="Price(€)"
                placeholderTextColor={col.mainColor}
                onChangeText={(price) => this.setState({price:price}) }
                />
                

            </View>
            <View style={styles.textInputContainer}>
            <Picker
                selectedValue={this.state.selectedOptions}
                style={{ height: 50, width: "90%",alignItem:"center",color:col.mainColor, backgroundColor: col.textColorPrimary, borderWidth:0,borderRadius:15}}
                onValueChange={(itemValue, itemIndex) =>
                    this.setState({selectedOptions:itemValue})
                }>
                <Picker.Item label="For consumers" value="potrosniki" />
                <Picker.Item label="For animals" value="zivali" />
                <Picker.Item label="For providers" value="ponudniki" />
                </Picker>
            </View>
            </View>
            <View style={{flex:2, alignItems:'center',justifyContent:'center',bottom:40}}>
                <Pressable style={styles.loginButtonStyle}>
                    <Text style={{color:col.secondaryColor,fontSize:20}} >POST</Text>
                </Pressable>
            </View>
        </MainBackground>
      );
    }
};
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: 'transparent',
    },imageContainer: {
        height:"100%",
        width:"100%"
      },
      title:{
          fontSize:22
      },
      TextInputStyle:{
        backgroundColor: "#FFC0CB",
        borderRadius: 30,
        width: 200,
        height: 45,
        marginBottom: 20,
        alignItems: "center",
        textAlign: "center",
        color:col.mainColor,

      },textInputContainer:{
          height:70,
          width:"40%",
          justifyContent:"space-evenly",
          alignItems:"center",
          textAlign:"center",
          backgroundColor:col.textColorPrimary,
          borderRadius:20,
      },
      loginButtonStyle:{
          height:65,
          width:"50%",
          alignItems:'center',
          justifyContent:'center',
          
      }
  });
  
  
const mapStateToProps = (state) => {
    const { dataReducer2 } = state
    return { dataReducer2 }
  };
  const mapDispatchToProps = dispatch => (
    bindActionCreators({
        addPost:addPost
    }, dispatch)
  );
  
  export default connect(mapStateToProps,mapDispatchToProps)(FoodFeedScreen);